.resPend {
  display: flex;
  border-radius: 10px;
  background-color: #fff;
  margin: 2px;
  margin-bottom: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  align-items: center;
}

.datosPend {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 40em;
}


.botonesOrdena {
  position: absolute;
  padding: 8px;
  border-radius: 10px;
  top: 0px;
  right: 0px;
  ;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 3rem;
}

.innbtn {
  font-size: 1em;
  background: transparent;
  display: block;
  color: #333;
  border: none;
  margin: 0 auto;
}

.innbtn:hover {
  color: darkgray;
}

.innbtn:active {
  transform: scale(1);
}

.labelP {
  color: #333;
  display: inline-block;
  margin-right: 3px;
  width: 8em;
  text-align: end;
}

.inpEst {
  width: 19em;
}

.textareaP {
  width: 33em;
  resize: none;
  margin: 5px;
}

.textareaDesc {
  width: 35rem;
  height: 3rem;
  resize: none;
  margin: 5px;
  border-radius: 5px;
  font-size: large;
}

select {
  width: 20em;
  margin: 5px;
}

:disabled {
  background-color: white;
}

.renglonpendiente {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tituloP {
  color: #fff;
  width: 35em;
  text-align: center;
  margin: 5px;
}

.estatus {
  margin-left: 5px;
}

@media only screen and (max-width: 599px) {
  .botonesOrdena {
    width: 4rem;
  }

  .resPend {
    width: 24rem;
  }

  .datosPend {
    width: 100%;
  }

  .detallePendiente {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .renglonpendiente {
    display: flex;
    align-items: center;
    margin: 5px;
  }

  .labelP {
    width: 5rem;
    font-size: x-small;
  }

  .tituloP {
    width: 18rem;
  }

  .textareaP {
    width: 13rem;
  }

  .inpEst {
    width: 50%;
  }

  .textareaDesc {
    font-size: x-small;
    width: 18rem;
  }
}