.header {
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #aaa;
  padding: 0 10%;
  position: sticky;
  top: 0;
  z-index: 99;

}

.hide {
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #aaa;
  padding: 0 10%;
  transition-property: all;
  transition-timing-function: ease-in;
  transition-duration: 5000ms;
}

.logo {
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);

  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  margin: 5px;
  font-size: x-large;
  font-weight: 800;
  color: #333;
  min-width: 100px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;

}

.header ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: baseline;
}

.header li {
  margin-left: 3rem;
}

.selec {
  color: #333;
  text-align: center;
  font-size: large;
  margin-bottom: 0px;
  font-weight: 600;
  width: 100%;
}

.lacasa {
  color: #333;
  text-align: center;
  font-size: xx-large;
  margin-bottom: 0px;
  width: 100%;
}


.badge {
  background-color: #cc2062;
  color: white;
  border-radius: 12px;
  padding: 0 1rem;
  margin-left: 0.5rem;
}

.divBurgerMenu {
  display: flex;
  align-items: flex-end;
  justify-items: flex-end;
}

.tipoUsuario {
  color: white;
}

@media only screen and (max-width: 599px) {
  .tipoUsuario {
    font-size: small;
  }

  .header { 
    justify-content: flex-start;
  }
}