.modal {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    background-color: white;
    padding: 1rem;
    text-align: center;
    z-index: 10;
    position: absolute;
    top: 10vh;
    max-height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
  }

  .botok {
      margin-top: 10px;
  }

  .contCheckboxes {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
  }

  .labelCheckboxes {
    white-space:nowrap;
  }
  .ordenItem {
      width: 100%;
      display: flex;
      justify-content: space-between;
  }

  .ordenIcon {
    margin-left: 5px;
  }