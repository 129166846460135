.back {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    background-color: #7e7d7d;
    padding: 1rem;
    text-align: center;
    width: 30rem;
    left: calc(50% - 15rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
}

.textos {
    display: inline-block;
    text-align: center;
    color: white;
    font-weight: 1000;
    font-size: small;
    width: 10em;
    bottom: 0px;
}

.textoTot {
    display: inline-block;
    text-align: end;
    color: white;
    font-weight: 1000;
    font-size: small;
    width: 14em;
    bottom: 0px;
}

.textoAct {
    display: inline-block;
    text-align: center;
    color: greenyellow;
    font-weight: 600;
    font-size: small;
    width: 10em;
    bottom: 0px;
    margin-bottom: 5px;

}

.textareaNE {
    width: 3em;
    resize: none;
    margin: 5px;
    text-transform: capitalize;
    text-align: end;
}

.textareaTot {
    width: 6em;
    resize: none;
    margin: 5px;
    text-transform: capitalize;
    text-align: end;
}

.divBotones {
    width: 95%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    position: sticky;
    bottom: -20px;
    padding-bottom: 10px;
    background-color: #7e7d7d;
    padding-right: 30px;
}

.botones {
    margin-top: 5px;
    margin-left: 20%;
}