.main {
    margin: 3rem auto;
    width: 90%;
    max-width: 90%;
}

@media only screen and (max-width: 599px) {
    .main {
        margin: 2px auto;
        width: 95%;
    }
}