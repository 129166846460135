body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    background-color: #333;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  h1 {
    color:#EEE;
    text-align: center;
    font-size:xx-large;
    margin-bottom: 0px;
    margin-top: 20px;
    width: 100%;
  }
  
  .centrarElem {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .TituloBoton {
    position: relative;
    display: flex;
    justify-content: center;
    width: 90%;
  }
  
  .botVerMateriales {
    margin-left: 5px;
    align-self: flex-end;
  }
  
  .botAgregaEst {
    margin-left: 5px;
    align-self: flex-end;
  }
  
  .contBotones {
    position: absolute;
    right: 5px;
    bottom: 5px;
    display: flex;
  }
  
  .TitEst {
    width: 100%;
  }
  