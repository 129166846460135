.modal {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    background-color: white;
    padding: 1rem;
    text-align: center;
    z-index: 10;
    position: absolute;
    top: 10vh;
    max-height: 80%;
    left: calc(50% - 15rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
}

.botok {
    margin-top: 10px;
}

.botCancela {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 1px;
    margin-right: 1px;
}