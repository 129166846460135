.burguericon {
  position: absolute;
  text-align: end;
  top: 10px;
  right: 20px;
  display: flex;
  color: white;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: transparent;
  cursor: pointer;
  padding: 0;
  z-index: 1;
  font-size: 2rem;
}

.burguericon:hover {
  color: rgb(133, 87, 1);
}

.burguericon:active {
  margin: 1px;
}