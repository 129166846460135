.list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

@media only screen and (max-width: 599px) {
  .list {
    width: 90%;
  }
}
