  .modal {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    background-color: white;    
    text-align: center;
    z-index: 50;
    position: fixed;
    max-height: 80%;
    min-width: 30%;
    max-width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
  }

  .renglonBotones{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
  }

  .botok {
      margin-top: 10px;
      margin-bottom: 10px;
      margin-left: 5px;
      margin-right: 5px;
  }

  .titulo {
      width: 100%;
      background-color: #666;
      color: white;
      padding-top: 5px;
      padding-bottom: 5px;
  }

  .texto {
      margin-top: 10px;
      margin-left: 10px;
      margin-right: 10px;
      white-space: pre-wrap;
  }