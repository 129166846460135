body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    background-color: #333;
  }
  
  h1 {
    color: #eee;
    text-align: center;
    font-size: xx-large;
    margin-bottom: 0px;
    margin-top: 20px;
    width: 100%;
  }
  
  .subtitulo {
    color: #999;
    text-align: center;
    font-size: larger;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 20px;
    width: 100%;
  }
  
  .labelP {
    color: white;
    display: inline-block;
    margin-right: 5px;
    width: 20em;
    text-align: end;
  }
  
  .labelFP {
    color: white;
    display: inline-block;
    margin-right: 5px;
    width: 20em;
    text-align: center;
  
  }
  
  .botAgregar {
    align-self: flex-end;
    cursor: pointer;
  }
  
  .textareaNE {
    width: 5em;
    resize: none;
    margin: 5px;
    text-transform: none;
  }
  .centrarElem {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .TituloBoton {
    position: relative;
    display: flex;
    justify-content: center;
    width: 90%;
  }
  
  .botVerMateriales {
    margin-left: 5px;
    align-self: flex-end;
  }
  
  .botones {
    margin-top: 20px;
    margin-left: 5px;
    align-self: flex-end;
    cursor: pointer;
  }
  
  
  
  .contBotones {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  
  .TitEst {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
  }
  