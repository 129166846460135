.back {
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    width: 100px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px;
    justify-content: space-between;
  }
  
  .nombre {
    display: inline-block;
    text-align: center;
    color: #333;
    font-weight: 1000;
    font-size: small;
    width: 100%;
    bottom: 0px;
  }
  
  .contratovenc {
    display: inline-block;
    text-align: center;
    color: #333;
    background-color: #f30;
    font-weight: 1000;
    font-size: small;
    width: 100%;
    bottom: 0px;
  }
  
  .contratovenc60 {
    display: inline-block;
    text-align: center;
    color: #33F;
    background-color: #fF0;
    font-weight: 1000;
    font-size: small;
    width: 100%;
    bottom: 0px;
  }
  .otros {
    display: inline-block;
    text-align: center;
    color: #333;
    font-size: small;
    width: 100%;
    bottom: 0px;
  }
  
  .btnAdeuda {
    margin-left: 5px;
    font-size: xx-small;
  }
  
  .fotoEstud {
    width: 50%;
  }
  
  .divBotones {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    justify-content: center;
    z-index: 1;
  }
  
  .innbtn {
    margin-left: 5px;
    margin-bottom: 5px;
    z-index: 1;
  }
  
  @media only screen and (max-width: 599px) {
    .back {
      width: 150px;
    }
  }
  