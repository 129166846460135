.modal {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  background-color: #444;
  padding: 1rem;
  text-align: center;
  width: 80%;
  z-index: 15;
  position: fixed;
  top: 17vh;
  max-height: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
}

.contBotTop {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: sticky;
  top: -20px;
  padding-top: 10px;
  padding-bottom: 0px;
  background-color: #444;
  z-index: 10;
}

.botCancela {
  top: 0;
}


.list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}


@media only screen and (max-width: 599px) {
  .list {
    width: 90%;
  }
}