.mainMenu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #666;
  text-align: left;
  padding: 2rem;
  position: absolute;
  right: 0;
  top: 0;
  transition: transform 0.3s ease-in-out;
  z-index: 2;
}
.liga {
  font-size: 1rem;
  padding: 1rem 0;
  font-weight: bold;
  color: white;
  text-decoration: none;
  transition: color 0.2s linear;
  z-index: 2;
  @media (max-width: 576px) {
    font-size: 1.5rem;
    text-align: center;
  }
}
.liga:hover {
  color: #343078;
}

