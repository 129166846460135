.labelP {
  color: white;
  display: inline-block;
  margin-right: 3px;
  width: 10em;
  text-align: end;
}
.textareaNE {
  width: 20em;
  resize: none;
  margin: 5px;
}

.tituloP {
  background-color: #eee;
  font-weight: 700;
  width: 28em;
  text-align: center;
  color: #333;
}
.divBotones {
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: sticky;
  bottom: -20px;
  margin-top: 10px;
  padding-bottom: 10px;
  padding-right: 30px;
}
.lista {
    list-style:decimal;
    padding-left: 10%;
    padding-right: 10%;
    width: 25em;
    color:#eee;
  }

.puntolista {
    width: 23em;
}

.sangria {
    margin-left: 20px;
}
.azul {
    color:dodgerblue;
    font-weight: 700;
}
@media only screen and (max-width: 599px) {
  .labelP {
    width: 40%;
    font-size: small;
  }

  .textareaNE {
    width: 50%;
  }

  .tituloP {
    width: 95%;
  }
}
