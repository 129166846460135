.modal {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  background-color: white;
  padding: 1rem;
  text-align: center;
  z-index: 10;
  position: absolute;
  top: 10vh;
  max-height: 80%;
  left: calc(50% - 15rem);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
}

.nombre {
  width: 100%;
}
.botok {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.contCheckboxes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
