.App {
  text-align: center;
}

.contPrinc {
  height: 90vh;
	display: flex;
	justify-content: center;
	align-items: center;
  background-color: #333;
}