.login-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 30px;
    align-content: center;
    background-color: #AAA;
    border-radius: 15px;
  }

.loginDet {
    height: 30%;
	display: flex;
	flex-direction: column;
}
.titulo {
    font-family: Cursive;
	font-size: 200%;
	color: #333a98;
}
input {
	margin: 5px;
}
.inputC
{
	width:200px;
	height:30px;
	margin:16px 20px;
	border:0;
	border-radius:7px;
	outline:0;
	display:block;
	padding-left:16px;
	font-size:large;
	font-family:'Open Sans';
	font-weight:200;
	-webkit-font-smoothing: antialiased;
}

.inputB {
    height: 2rem;
	width: 50%;
	align-self: flex-end;
	background:#02676f;
	border-radius:3px;
	border:2px; 	
	text-align:center; 
	color:#fff;
	font-size:14px;
	font-weight:bold;	
}

.inputB:hover {
	background:#008D99;
	color: #333;
}

.inputB:active {
	margin: 1px;
}

.ver {
	font-size: x-small;
	color: #333a98;
}