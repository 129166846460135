.modal {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  background-color: #666;
  padding: 1rem;
  text-align: center;
  width: 35rem;
  z-index: 10;
  position: fixed;
  top: 15vh;
  max-height: 80%;
  left: calc(50% - 15rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
}

.divBotones {
  width: 95%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  position: absolute;
  top: 0px;
  right: 5px;
  padding-bottom: 10px;
  background-color: #666;
  
}

.divFichas {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.botones {
  margin-top: 5px;
  margin-left: 20%;
}

.labelP {
  color: white;
  display: inline-block;
  margin-right: 3px;
  width: 10em;
  text-align: end;
}
.textareaNE {
  width: 20em;
  resize: none;
  margin: 5px;
  text-transform: uppercase;
}
.email {
  text-transform: lowercase;
}

.tituloP {
  background-color: #eee;
  font-weight: 700;
  width: 28em;
  text-align: center;
  color: #333;
}

.innbtn2 {
  position: relative;
  margin: 10px;
}

.otrasFotos {
  width: 50%;
  margin-top: 5px;
}

@media only screen and (max-width: 599px) {
  .modal {
    width: 94vw;
    left: 3vw;
    padding: 0;
  }
  .labelP {
    width: 40%;
    font-size: small;
  }

  .textareaNE {
    width: 50%;
  }
  .tituloP {
    width: 90%;
  }
}
