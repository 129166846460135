.modal {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  background-color: #666;
  padding: 1rem;
  text-align: center;
  width: 30rem;
  z-index: 10;
  position: fixed;
  top: 15vh;
  max-height: 80%;
  left: calc(50% - 15rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.botTop {
  margin-right: 20px;
}

.h1loc {
  color: #eee;
  text-align: center;
  margin-bottom: 0px;
  margin-top: 0px;
  width: 100%;
}

.contBotTop {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: sticky;
  top: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: #666;
  z-index: 10;
}


.divBotones {
  width: 95%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  bottom: -20px;
  padding-bottom: 10px;
  background-color: #666;
  padding-right: 30px;
}

.botones {
  margin-top: 5px;
  margin-left: 20%;
}

.labelP {
  color: white;
  display: inline-block;
  margin-right: 3px;
  width: 10em;
  text-align: end;
}

.textareaNE {
  width: 18em;
  resize: none;
  margin: 5px;
  text-transform: uppercase;
}

.textareaNE1 {
  width: 18em;
  font-size: small;
  margin: 5px;
  text-align: start;
}

.tituloP {
  background-color: #eee;
  font-weight: 700;
  width: 28em;
  text-align: center;
  color: #333;
}

.innbtn2 {
  position: relative;
  margin: 10px;
}

.contSelect {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 5px;
}

@media only screen and (max-width: 599px) {
  .modal {
    width: 94vw;
    left: 3vw;
    padding: 0;
  }

  .labelP {
    width: 40%;
    font-size: small;
  }

  .textareaNE {
    width: 50%;
  }

  .tituloP {
    width: 90%;
  }
}