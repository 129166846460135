.modal {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    background-color: #666;
    padding: 1rem;
    text-align: center;
    width: 20rem;
    z-index: 20;
    position: fixed;
    top: 13vh;
    max-height: 80%;
    left: calc(50% - 10rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
  }
  
  .descExtra {
    border: 2px;
    border-style: solid;
    border-color: #333;
  }
  .divBotones {
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: sticky;
    bottom: -20px;
    padding-bottom: 10px;
    background-color: #666;
    padding-right: 30px;
  }
  
  .botones {
    margin-top: 5px;
    margin-left: 20%;
  }
  
  .labelP {
    color: white;
    display: inline-block;
    margin-right: 3px;
    width: 10em;
    text-align: end;
  }
  .labelCh {
    color: white;
    display: inline-block;
    margin-right: 3px;
    width: 15em;
    text-align: start;
  }
  .textareaNE {
    width: 20em;
    resize: none;
    margin: 5px;
    text-transform: uppercase;
  }
  .email {
    text-transform: lowercase;
  }
  
  .tituloP {
    background-color: #eee;
    font-weight: 700;
    width: 90%;
    text-align: center;
    color: #333;
    margin-bottom: 5px;
  }

  .tabla {
    width: 100%;
  }

  .tabbot {
    display: flex;
    width:min-content;
  }
  
  @media only screen and (max-width: 599px) {
    .modal {
      width: 94vw;
      left: 3vw;
      padding: 0;
    }
    .labelP {
      width: 40%;
      font-size: small;
    }
    
    .textareaNE {
      width: 50%;
    }
    .tituloP {
      width: 90%;
    }
  }