.modal {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  background-color: #666;
  padding: 1rem;
  text-align: center;
  width: 32rem;
  z-index: 10;
  position: fixed;
  top: 15vh;
  left: calc(50% - 16rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 80%;
  overflow-y: auto;
}

.divBotones {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
}

.botones {
  margin-top: 10px;
  margin-left: 10px;
}

.labelMateriales {
  color: #ddd;
}
@media only screen and (max-width: 599px) {
  .modal {
    width: 94vw;
    left: 3vw;
    padding: 0;
  }
  .datosPend {
    align-items: center;
  }
}
