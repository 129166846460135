.list {
    list-style: none;
    margin: 0;
    padding: 0;
    
  }
  
  @media only screen and (max-width: 599px) {
    .list {
      width: 100%;
    }
  }