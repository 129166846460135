.modal {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    background-color: #666;
    padding: 1rem;
    text-align: center;
    width: 80%;
    z-index: 15;
    position: fixed;
    top: 15vh;
    max-height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
}

.contBotTop {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: sticky;
    top: -17px;
    padding-top: 10px;
    padding-bottom: 0px;
    background-color: #666;
    z-index: 10;
}

.botAgregaEst1 {
    margin-left: 5px;
    margin-bottom: 5px;
    align-self: flex-end;
}

.TituloBoton {
    position: relative;
    display: flex;
    justify-content: center;
}

.TitEst {
    width: 100%;
}

.divBotones {
    width: 95%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    position: sticky;
    bottom: -20px;
    padding-bottom: 10px;
    background-color: #666;
    padding-right: 30px;
}

.botones {
    margin-top: 5px;
    margin-left: 5px;
}

.botCancela {
    top: 0;
}

.fotoEstud {
    margin-top: 5px;
    width: 50%;
}

.list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}

.otrasFotos {
    width: 50%;
    margin-top: 5px;
}

.innbtn {
    position: absolute;
    margin-left: 5px;
    margin-top: 5px;
}

.innbtn2 {
    position: relative;
    margin: 10px;
}

.textHab {
    width: 10em;
    resize: none;
    margin: 5px;
    text-transform: uppercase;
}

.labelP {
    color: white;
    display: inline-block;
    margin-right: 3px;
    margin-left: 3px;
    width: 10em;
    text-align: end;
}

.textareaNE {
    width: 20em;
    resize: none;
    margin: 5px;
    text-transform: capitalize;
}

.email {
    text-transform: lowercase;
}

.linea {
    background-color: #333;
    height: 1px;
}

.check {
    color: #eee;
}

.tituloP {
    background-color: #eee;
    font-weight: 700;
    width: 100%;
    text-align: center;
    color: #333;
}

.forma {
    width: 100%;
}

.textareaDesc {
    width: 100%;
    height: 3rem;
    resize: none;
    margin-top: 5px;
    border-radius: 5px;
}

.renglonpendiente {
    display: flex;
    align-items: center;
    margin: 5px;
}

.textareaP {
    width: 60%;
    resize: none;
    margin: 5px;
}


@media only screen and (max-width: 599px) {
    .modal {
        width: 94vw;
        left: 3vw;
        padding: 0;
    }

    .labelP {
        width: 40%;
        font-size: small;
    }

    .textareaNE {
        width: 50%;
    }

    .tituloP {
        width: 90%;
    }

    .textHab {
        width: 6em;
        resize: none;
        margin: 5px;
        text-transform: uppercase;
    }

    .textareaDesc {
        width: 90%;
    }
}