.modal {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  background-color: white;
  padding: 1rem;
  text-align: center;
  z-index: 10;
  position: absolute;
  top: 10vh;
  max-height: 80%;
  left: calc(50% - 15rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.contCheckboxes {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.divBotones {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
}

.botones {
  margin-left: 10px;
}

@media only screen and (max-width: 599px) {
  .modal {
    width: 94vw;
    left: 3vw;
    padding-left: 0;
    padding-right: 0;
  }
}
