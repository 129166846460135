.modal {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  background-color: #333;
  padding: 1rem;
  text-align: center;
  width: 34rem;
  z-index: 11;
  position: fixed;
  top: 20vh;
  max-height: 80%;
  left: calc(50% - 17rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.marco {
  border-radius: 6px;
  border-style: solid;
  border-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  padding: 1%;
}

.contNumRecibo {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: white;
  font-size: medium;
  font-weight: 800;
  padding-right: 7%;
  padding-left: 7%;
}

.contFechaEmision {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  color: white;
  padding-right: 15%;
  margin-top: 2px;
}

.contTextoNombre {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  color: white;
  padding-left: 10%;
  font-size: xx-small;
  margin-top: 2px;
}

.nombre {
  color: #eee;
  font-size: medium;
  font-weight: 600;
  text-decoration: underline;
}

.contTextoCantidad {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  color: white;
  padding-left: 5%;
  padding-right: 5%;
  font-size: xx-small;
  margin-top: 2px;
}

.textoCantidad {
  font-size: medium;
  font-weight: 600;
  text-decoration: underline;
}

.contConcepto {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  color: white;
  padding-left: 5%;
  font-size: medium;
  margin-top: 2px;
}

.textConcepto {
  font-size: medium;
  font-weight: 600;
  text-decoration: underline;
}

.contPeriodo {
  display: flex;
  justify-content: center;
  width: 100%;
  color: white;
  padding-left: 5%;
  font-size: medium;
  margin-top: 4px;
  margin-bottom: 4px;
}

.textoPeriodo {
  font-size: medium;
  font-weight: 600;
  text-decoration: underline;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.divBotones {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
}

.botones {
  margin-left: 10px;
}

@media only screen and (max-width: 599px) {
  .modal {
    width: 94vw;
    left: 3vw;
    padding-left: 0;
    padding-right: 0;

  }
}
