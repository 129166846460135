.modal {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  background-color: #333;
  padding: 1rem;
  text-align: center;
  width: 34rem;
  z-index: 11;
  position: fixed;
  top: 15vh;
  max-height: 80%;
  left: calc(50% - 17rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.marcoPago {
  position: relative;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  background-color: #ddd;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 95%;
  padding: 1%;
  margin-bottom: 10px;
}

.contPago {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.datoPago {
  color: #222;
  font-size: medium;
  font-weight: 600;
  padding-right: 7%;
  padding-left: 5px;
}

.tituloPago {
  white-space: nowrap;
  color: #333;
  font-size: small;
  margin-left: 10px;
}

.divBotones {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 5px;
}

.divBotonesPago {
  width: 10%;
  height: 90%;
  position: absolute;
  bottom: 5px;
  right: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.botones {
  margin-right: 10px;
}

.h1loc {
  color: #eee;
  text-align: center;
  margin-bottom: 0px;
  margin-top: 0px;
  width: 100%;
}

.botTop {
  margin-right: 20px;
}

.contBotTop {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: sticky;
  top: -20px;
  padding-top: 10px;
  padding-bottom: 5px;
  background-color: #333;
  z-index: 10;
}

.botCancela {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 10px;
  margin-right: 10px;
}

.botonesPago {
  margin-top: 1px;
}

@media only screen and (max-width: 599px) {
  .modal {
    width: 94vw;
    left: 3vw;
    padding-left: 0;
    padding-right: 0;
  }
}