.dialogoCambiaFoto {
    width: 20rem;
    position: fixed;
    top: 20vh;
    background-color: #333;

    border-radius: 5px;
    z-index: 20;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 3vh;
}

.contBotTop {
    border-radius: 5px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: sticky;
    top: -20px;
    padding-top: 10px;
    padding-bottom: 5px;
    background-color: #333;
    z-index: 10;
}

.fotoPreview {
    width: 10rem;
}

.innbtn {
    width: 2rem;
}

.texto {
    color: #fff;
}

.botCancela {
    position: absolute;
    top: 0;
    right: 0;
}

.titulo {
    margin-top: 0;
    color: #ddd;
}