.dialogoRenuevaContrato {
    width: 26rem;
    position: fixed;
    top: 20vh;
    background-color: #eee;
    border-radius: 5px;
    z-index: 20;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 3vh;
  }
  
  .fotoPreview {
    width: 10rem;
  }
  
  .innbtn {
    margin-top: 1rem;
    width: 2rem;
  }
  
  .titulo {
    color: #333;
  }
  
  .textareaNE {
    width: 5em;
    resize: none;
    margin: 5px;
  }
  
  .labelP {
    display: inline-block;
    margin-right: 3px;
    width: 10em;
    text-align: end;
  }
  