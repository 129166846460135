.back {
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    width: 200px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px;
    justify-content: space-between;
  }
  
  .nombre {
    display: inline-block;
    text-align: center;
    color: #333;
    font-weight: 1000;
    font-size: small;
    width: 100%;
    bottom: 0px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  .otros {
    display: inline-block;
    text-align: center;
    color: #333;
    font-size: small;
    width: 100%;
    bottom: 0px;
  }

  .linea {
    background-color: black;
    color: black;
    height: 2px;
    width: 100%;
  }
  .divBotones {
    width: 50%;
    display: flex;
    position: relative;
    flex-wrap: wrap;
    justify-content:space-evenly;
    z-index: 1;
    margin-top: 5px;
  }
  
  .innbtn {
    margin-left: 5px;
    margin-bottom: 5px;
  }
  
  @media only screen and (max-width: 599px) {
    .back {
      width: 150px;
    }
  }
  